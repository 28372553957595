<template>
  <div class="container">
    <div class="position-relative">
      <!-- begin::Legacy device alert -->
      <template v-if="equipment.isDeviceLinked() && device.isLegacyDevice()">
        <div class="loading-container loading-overlap mt-n5 mx-n5 h-auto">
          <div class="loading-backdrop rounded opacity-20" />
          <div class="loading-block">
            <div class="alert alert-custom alert-light-info ml-auto mr-auto w-100 p-3 d-block">
              <div
                class="alert-text"
                v-html="$t('sunbeds.legacy_device_warning')"
              />
            </div>
          </div>
        </div>
      </template>
      <!-- end::Legacy device alert -->

      <div v-if="equipment.isDeviceLinked() && $auth.isResourceFromIndex('users')" class="row mt-3 border-bottom pb-3">
        <div class="col-12 col-md">
          <h5 class="overflow-ellipsis">Automatic time synchronization</h5>
          <span>
            This feature will automatically set your sunbed's time if it deviates significantly from the local time.<br />
            The set time will be based on your sunbed's location and will be set between 01:00 and 04:00. Please make sure your sunbed's location is filled out correctly.
          </span>
        </div>

        <div class="col-auto mt-3 my-md-auto text-right">
          <span :class="['ml-auto switch', { 'switch-success': (isTimeSynchronizationEnabled === true) }]">
            <label>
              <input
                name="distributor_access"
                type="checkbox"
                :checked="(isTimeSynchronizationEnabled === true)"
                :disabled="$auth.hasPermission('equipment.settings.time_synchronization') === false || isLoadingForm"
                @click.prevent="onTimeSynchronizationChange($event.target.checked)"
              />
              <span />
            </label>
          </span>
        </div>
      </div>

      <div v-if="equipment.isDeviceLinked() && $auth.isResourceFromIndex('users') && $auth.hasPermission('equipment.controller.reset')" class="row mt-3 border-bottom pb-3">
        <div class="col-12 col-md">
          <h5 class="overflow-ellipsis">Restart sunbed</h5>
          <span>
            This will restart the sunbed.
          </span>
        </div>

        <div class="col mt-3 my-md-auto text-right">
          <button
            type="button"
            class="btn btn-warning w-150px"
            :disabled="isLoadingForm || device.isLegacyDevice()"
            @click.prevent="restartController()"
          >
            Restart sunbed
          </button>
        </div>
      </div>

      <div v-if="equipment.isDeviceLinked() && $auth.isResourceFromIndex('users') && $auth.hasPermission('equipment.controller.factory-reset')" class="row mt-3 border-bottom pb-3">
        <div class="col-12 col-md">
          <h5 class="overflow-ellipsis">Factory reset <span class="label label-sm label-danger label-inline">Caution</span></h5>
          <span>
            This will set the sunbed to factory defaults and restart the sunbed.
          </span>
        </div>

        <div class="col mt-3 my-md-auto text-right">
          <button
            type="button"
            class="btn btn-danger w-150px"
            :disabled="isLoadingForm || device.isLegacyDevice()"
            @click.prevent="onFactoryResetClick"
          >
            Factory reset
          </button>
        </div>
      </div>
    </div>

    <div v-if="$auth.isResourceFromIndex('users') && $auth.hasPermission('companies.equipment.remove')" class="row mt-3 border-bottom pb-3">
      <div class="col-12 col-md">
        <h5 class="overflow-ellipsis">Unlink sunbed <span class="label label-sm label-danger label-inline">Caution</span></h5>
        <span>
          This option will unlink the sunbed from your current company.
        </span>
      </div>

      <div class="col mt-3 my-md-auto text-right">
        <button
          type="button"
          class="btn btn-danger w-150px"
          :disabled="isLoadingForm"
          @click.prevent="unlinkResource()"
        >
          Unlink sunbed
        </button>
      </div>
    </div>

    <div v-if="$auth.isResourceFromIndex('users') && $auth.hasPermission('equipment.delete')" class="row mt-3 border-bottom pb-3">
      <div class="col-12 col-md">
        <h5 class="overflow-ellipsis">Delete sunbed <span class="label label-sm label-danger label-inline">Caution</span></h5>
        <span>
          This option will permanently delete the current sunbed.
        </span>
      </div>

      <div class="col mt-3 my-md-auto text-right">
        <button
          type="button"
          class="btn btn-danger w-150px"
          :disabled="isLoadingForm"
          @click.prevent="removeResource()"
        >
          Delete sunbed
        </button>
      </div>
    </div>

    <div v-if="$auth.isResourceFromIndex('users') && $auth.hasPermission('equipment.settings.prohibit_distributor_access')" class="row mt-3 border-bottom pb-3">
      <div class="col-12 col-md">
        <h5 class="overflow-ellipsis">Prohibit distributor access</h5>
        <span>
          When enabled, the distributor will not be able to view information regarding this sunbed.
        </span>
      </div>

      <div class="col-auto mt-3 my-md-auto text-right">
        <span :class="['ml-auto switch', { 'switch-warning': (isDistributorAccessProhibited === true) }]">
          <label>
            <input
              name="distributor_access"
              type="checkbox"
              :checked="(isDistributorAccessProhibited === true)"
              :disabled="isLoadingForm"
              @click.prevent="onDistributorAccessChange($event.target.checked)"
            />
            <span />
          </label>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import Equipment from '@/libs/classes/equipment';
import Device from '@/libs/classes/device';
import { CompanyClass as Company, company as companyLib } from '@vedicium/core-vue/build/modules/company';

export default {
  props: {
    equipment: {
      type: Equipment,
      default: null,
    },
    device: {
      type: Device,
      default: null,
    },
  },
  data () {
    return {
      isLoadingForm: false,
    };
  },
  computed: {
    isTimeSynchronizationEnabled () {
      if (!this.equipment || !this.equipment.settings) {
        return false;
      }

      const setting = this.equipment.settings.find((row) => row.key === 'time_synchronization');
      return (setting && setting.payload) || false;
    },
    isDistributorAccessProhibited () {
      if (!this.equipment || !this.equipment.settings) {
        return false;
      }

      const setting = this.equipment.settings.find((row) => row.key === 'prohibit_distributor_access');
      return (setting && setting.payload) || false;
    },
  },
  watch: {
    $route (to) {
      if (to.name !== this.$route.name) {
        return;
      }

      this.onRoute();
    },
  },

  async mounted () {
    await this.$nextTick();

    this.onRoute();
  },

  methods: {
    onRoute () {
      // Open removeResource modal when query 'remove' is set
      if (this.$route && this.$route.query) {
        if (typeof this.$route.query.remove !== typeof undefined) {
          this.removeResource();
          this.$router.replace({
            ...this.$router.currentRoute,
            query: {
              ...this.$router.currentRoute.query,
              remove: undefined,
            },
          });
        }

        if (typeof this.$route.query.restart_sunbed !== typeof undefined) {
          this.restartController();
          this.$router.replace({
            ...this.$router.currentRoute,
            query: {
              ...this.$router.currentRoute.query,
              restart_sunbed: undefined,
            },
          });
        }
      }
    },

    async onTimeSynchronizationChange (value) {
      if (!this.equipment || typeof value !== 'boolean') {
        return;
      }

      this.$set(this, 'isLoadingForm', true);
      try {
        const response = await this.$ws.put(`${Equipment.uri}/${this.equipment._meta.guid}/time_synchronization`, {
          body: {
            enabled: value,
          },
          query: {
            persistent: true,
          },
        });

        this.$eventhub.emit(`document:${response._meta.index}:${response._meta.guid}:update`, response);
      } catch (e) {
        this.$errors.handle(e, { ui_element: true });
        console.error(e);
      } finally {
        this.$set(this, 'isLoadingForm', false);
      }
    },
    async onDistributorAccessChange (value = null) {
      if (!this.equipment || typeof value !== 'boolean') {
        return;
      }

      if (value === true) {
        const vm = this;
        vm.$metronic.eventhub.emit('modals:layout:confirmation:open', {
          title: 'Prohibit distributor access',
          message: `Are you sure that you want to prohibit distributor access for <strong>${vm.equipment.alias || vm.equipment.serial}</strong>?`,
          variant: 'warning',
          buttonText: 'I\'m sure',
          async onSubmit () {
            vm.$set(vm, 'isLoadingForm', true);
            try {
              const response = await vm.$ws.put(`${Equipment.uri}/${vm.equipment._meta.guid}/prohibit_distributor_access`, {
                body: {
                  enabled: true,
                },
                query: {
                  persistent: true,
                },
              });

              vm.$eventhub.emit(`document:${response._meta.index}:${response._meta.guid}:update`, response);
            } catch (e) {
              vm.$errors.handle(e, { ui_element: true });
              console.error(e);
            } finally {
              vm.$set(vm, 'isLoadingForm', false);
            }
          },
        });
        return;
      }

      this.$set(this, 'isLoadingForm', true);
      try {
        const response = await this.$ws.put(`${Equipment.uri}/${this.equipment._meta.guid}/prohibit_distributor_access`, {
          body: {
            enabled: false,
          },
          query: {
            persistent: true,
          },
        });

        this.$eventhub.emit(`document:${response._meta.index}:${response._meta.guid}:update`, response);
      } catch (e) {
        this.$errors.handle(e, { ui_element: true });
        console.error(e);
      } finally {
        this.$set(this, 'isLoadingForm', false);
      }
    },
    restartController () {
      if (!this.equipment || this.equipment.isDeviceLinked() === false) {
        return;
      }

      const vm = this;
      vm.$metronic.eventhub.emit('modals:layout:confirmation:open', {
        title: 'Restart sunbed',
        message: `Are you sure that you want to restart <strong>${vm.equipment.alias || vm.equipment.serial}</strong>?<br />This action can take up to 10 seconds to take effect.`,
        variant: 'warning',
        buttonText: 'Restart',
        async onSubmit () {
          try {
            await vm.$ws.put(`${Equipment.uri}/${vm.equipment._meta.guid}/controller/reset`);
          } catch (e) {
            vm.$errors.handle(e, { component: vm, ui_element: true });
            console.error(e);
          }
        },
      });
    },
    onFactoryResetClick () {
      const vm = this;
      vm.$metronic.eventhub.emit('modals:layout:confirmation:open', {
        title: 'Factory reset',
        message: 'Are you sure that you want to reset the sunbed to its factory defaults?<br />All configured settings will be lost.',
        buttonText: 'I\'m sure',
        async onSubmit () {
          try {
            await vm.$ws.put(`${Equipment.uri}/${vm.equipment._meta.guid}/controller/factory-reset`);
          } catch (e) {
            vm.$errors.handle(e, { ui_element: true });
            console.error(e);
          }
        },
      });
    },
    removeResource () {
      const vm = this;
      vm.$metronic.eventhub.emit('modals:layout:confirmation:open', {
        title: 'Delete sunbed',
        message: `Are you sure that you want to delete <strong>${vm.equipment.serial}</strong>?<br /><br />Please type the serialnumber of the sunbed to confirm:`,
        verifyString: vm.equipment.serial,
        async onSubmit () {
          await vm.equipment.remove();

          vm.$router.push('/sunbeds');
        },
      });
    },

    unlinkResource () {
      const vm = this;
      vm.$metronic.eventhub.emit('modals:layout:confirmation:open', {
        title: 'Unlink sunbed',
        message: `Are you sure that you want to unlink <strong>${vm.equipment.serial}</strong> from your current company?<br /><br />Please type the serialnumber of the sunbed to confirm:`,
        verifyString: vm.equipment.serial,
        async onSubmit () {
          try {
            await vm.$ws.delete(`${Company.uri}/${companyLib.current._meta.guid}/equipment/${vm.equipment._meta.guid}`);
          } catch (e) {
            vm.$errors.handle(e, { ui_element: true });
            console.error(e);
            return;
          }

          vm.$router.push('/sunbeds');
        },
      });
    },
  },
};
</script>
